import CustomerCareKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    CustomerCareKeys.WIZARD.STEPS.OFFERS,
    CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CustomerCareKeys.WIZARD.STEPS.CHECKOUT
];

export const STATE_OR_NAME = 'index.customercare.customer.orders.addofferorder';
export const REVIEW_SAVED_CART = 'index.customercare.customer.orders.savedcart';
export const REVIEW_SAVED_CART_INSTANCES = 'index.customercare.customer.orders.savedcartForInstances';
export const REVIEW_STORE_ORDER_ADD =
    'index.customercare.customer.orders.reviewStoreOrder';
export const ADD_OFFERING_INSTANCES = 'index.customercare.customer.orders.addofferingInstances';