import {values} from 'ramda';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import {SUBSCRIBER_ID_HEADER} from 'invision-core/src/api/constants';
import ThunkHelper from 'invision-core/src/api/thunk.helper';
import {retrieveConvergentBillerOrderDetailsPromise} from './customer.convergent.biller.actions';

export const APPLY_CREDIT_CONSTANTS = {
    BEGIN: 'APPLY_CREDIT_BEGIN',
    SUCCESS: 'APPLY_CREDIT_SUCCESS',
    FAILURE: 'APPLY_CREDIT_FAILURE'
};
export const RESUBMIT_ORDER_CONSTANTS = {
    BEGIN: 'RESUBMIT_ORDER_BEGIN',
    SUCCESS: 'RESUBMIT_ORDER_SUCCESS',
    FAILURE: 'RESUBMIT_ORDER_FAILURE'
};
export const COMPLETE_ORDER_CONSTANTS = {
    BEGIN: 'COMPLETE_ORDER_BEGIN',
    SUCCESS: 'COMPLETE_ORDER_SUCCESS',
    FAILURE: 'COMPLETE_ORDER_FAILURE'
};
export const CLEAR_APPLY_CREDIT_ERRORS = 'CLEAR_APPLY_CREDIT_ERRORS';

const APPLY_CREDIT_EVENTS = [APPLY_CREDIT_CONSTANTS.BEGIN, APPLY_CREDIT_CONSTANTS.SUCCESS, APPLY_CREDIT_CONSTANTS.FAILURE];
const RESUBMIT_ORDER_EVENTS = [RESUBMIT_ORDER_CONSTANTS.BEGIN, RESUBMIT_ORDER_CONSTANTS.SUCCESS, RESUBMIT_ORDER_CONSTANTS.FAILURE];
const COMPLETE_ORDER_EVENTS = [COMPLETE_ORDER_CONSTANTS.BEGIN, COMPLETE_ORDER_CONSTANTS.SUCCESS, COMPLETE_ORDER_CONSTANTS.FAILURE];

export const clearApplyCreditErrors = () => {
    return {
        type: CLEAR_APPLY_CREDIT_ERRORS
    };
};

export const applyOrderCredit = (customerId, creditDetails) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, APPLY_CREDIT_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/ProcessCredit',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: creditDetails
        });
    };
};

export const resubmitOrder = (customerId, orderId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, RESUBMIT_ORDER_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/ResubmitOrder',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                Id: orderId
            }
        }).then(() => {
            return retrieveConvergentBillerOrderDetailsPromise(dispatch, customerId, orderId);
        });
    };
};

export const completeOrder = (customerId, orderId) => {
    return (dispatch) => {
        return ThunkHelper(dispatch, COMPLETE_ORDER_EVENTS, {
            method: 'post',
            url: 'SubscriberManagement/CompleteOrderPayment',
            headers: {
                [SUBSCRIBER_ID_HEADER]: customerId
            },
            data: {
                OrderId: orderId
            }
        }, {
            customerId,
            orderId
        });
    };
};

export const CANCEL_ORDER_CONSTANTS = {
    BEGIN: 'CANCEL_ORDER_BEGIN',
    SUCCESS: 'CANCEL_ORDER_SUCCESS',
    FAILURE: 'CANCEL_ORDER_FAILURE'
};

export const CANCEL_PENDING_ORDER_CONSTANTS = {
    BEGIN: 'CANCEL_PENDING_ORDER_BEGIN',
    SUCCESS: 'CANCEL_PENDING_ORDER_SUCCESS',
    FAILURE: 'CANCEL_PENDING_ORDER_FAILURE'
};



export const cancelOrderPromise = (dispatch, customerId, orderId, issueRefund = true) => {
    return ThunkHelper(dispatch, values(CANCEL_ORDER_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/CancelOrder',
        data: {
            Id: orderId,
            IssueRefund: issueRefund
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const cancelPendingOrderPromise = (dispatch, customerId, orderId) => {
    return ThunkHelper(dispatch, values(CANCEL_PENDING_ORDER_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/CancelPendingOrder',
        data: {
            OrderId: orderId,
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    }, {
        customerId
    });
};

export const cancelOrder = (customerId, orderId, issueRefund = true) => {
    return (dispatch) => {
        return cancelOrderPromise(dispatch, customerId, orderId, issueRefund);
    };
};

export const cancelPendingOrder = (customerId, orderId) => {
    return (dispatch) => {
        return cancelPendingOrderPromise(dispatch, customerId, orderId);
    };
};

export const RETRIEVE_ORDER_SERVICE_DETAILS = {
    BEGIN: 'RETRIEVE_ORDER_SERVICE_DETAILS_BEGIN',
    SUCCESS: 'RETRIEVE_ORDER_SERVICE_DETAILS_SUCCESS',
    FAILURE: 'RETRIEVE_ORDER_SERVICE_DETAILS_FAILURE'
};

export const retrieveOrderServiceDetailsPromise = (dispatch, orderId, customerId) => {
    return ThunkHelper(dispatch, values(RETRIEVE_ORDER_SERVICE_DETAILS), {
        method: 'post',
        url: 'Workflow/RetrieveOrderServiceDetails',
        data: {
            OrderId: orderId,
            ReturnAllAvailableAttributeTypes: true
        }
    }, {
        CustomerId: customerId
    });
};

export const retrieveOrderServiceDetails = (orderId, customerId) => {
    return (dispatch) => {
        return retrieveOrderServiceDetailsPromise(dispatch, orderId, customerId);
    };
};

export const UPDATE_ORDER_SERVICE_DETAILS = {
    BEGIN: 'UPDATE_ORDER_SERVICE_DETAILS_BEGIN',
    SUCCESS: 'UPDATE_ORDER_SERVICE_DETAILS_SUCCESS',
    FAILURE: 'UPDATE_ORDER_SERVICE_DETAILS_FAILURE'
};

export const updateOrderServiceDetailsPromise = (dispatch, orderId, orderItems) => {
    return ThunkHelper(dispatch, values(UPDATE_ORDER_SERVICE_DETAILS), {
        method: 'post',
        url: 'Workflow/UpdateOrderServiceDetails',
        data: {
            OrderId: orderId,
            OrderItems: orderItems
        }
    });
};

export const updateOrderServiceDetails = (orderId, orderItems) => {
    return (dispatch) => {
        return updateOrderServiceDetailsPromise(dispatch, orderId, orderItems);
    };
};


export const SEARCH_INVENTORY_FOR_UPDATE = {
    BEGIN: 'SEARCH_INVENTORY_FOR_UPDATE_BEGIN',
    SUCCESS: 'SEARCH_INVENTORY_FOR_UPDATE_SUCCESS',
    FAILURE: 'SEARCH_INVENTORY_FOR_UPDATE_FAILURE'
};
export const searchInventoryForUpdatingAttributesPromise = (dispatch, inventoryTypeId, customerId) => {
    return ThunkHelper(dispatch, values(SEARCH_INVENTORY_FOR_UPDATE), {
        method: 'post',
        url: 'SubscriberManagement/SearchInventory',
        data: {
            InventoryTypeId: inventoryTypeId,
            PageSize: 6
        },
        headers: {
            [SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};
export const searchInventoryForUpdatingAttributes = (inventoryTypeId) => {
    return (dispatch, getState) => {
        const routeParams = RouteParams(getState());
        return searchInventoryForUpdatingAttributesPromise(dispatch, inventoryTypeId, routeParams.customerId);
    };
};

export const SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS = {
    BEGIN: 'SEARCH_SUBSCRIBER_OFFERINGS_BEGIN',
    SUCCESS: 'SEARCH_SUBSCRIBER_OFFERINGS_SUCCESS',
    FAILURE: 'SEARCH_SUBSCRIBER_OFFERINGS_FAILURE'
};

export const searchSubscriberOfferingsPromise = (dispatch, params, requestObj) => {
    return ThunkHelper(dispatch, values(SEARCH_SUBSCRIBER_OFFERINGS_CONSTANTS), {
        method: 'post',
        url: 'SubscriberManagement/SearchSubscriberOfferings',
        headers: {
            [SUBSCRIBER_ID_HEADER]: params.customerId
        },
        data: {
            IncludeCoolingOff: true,
            IncludeOfferingTotals: params.includeOfferingTotals,
            OfferingInstanceIds: params.offeringInstanceIds,
            OfferName: params.offerName,
            PageNumber: params.pageNumber,
            PageSize: params.pageSize,
            Status: params.status
        }
    }, requestObj);
};

export const searchSubscriberOfferings = (params, requestObj) => {
    return (dispatch) => {
        return searchSubscriberOfferingsPromise(dispatch, params, requestObj);
    };
};