import concat from 'ramda/src/concat';
import find from 'ramda/src/find';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import toPairs from 'ramda/src/toPairs';
import values from 'ramda/src/values';

import {createSelector} from 'reselect';
import moment from 'moment';

import i18n from 'invision-core/src/components/i18n/i18n';
import {
    MetadataCodeSelector,
    MetadataCodeLoadedSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

import CustomerLocaleKeys from '../../locales/keys';
import {
    RouteParams,
    RoutePreviousState,
    SelectedCustomerSelector
} from './customer.selectors';

export const GLYPH = {
    ARCHIVE: 'archive',
    CLOCK_O: 'clock-o',
    NET_LINK: 'net_link',
    SEARCH: 'search'
};

const RecoverableContentActivitySelector = (state) => {
    return pathOr(null, ['customercare', 'recoverableUiState', 'contentActivity'], state);
};

export const SelectedItemIdSelector = createSelector(
    [RecoverableContentActivitySelector],
    (uiState) => {
        return uiState.selectedItemId;
    }
);

export const ProductIdRouteParamSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.productId;
    }
);

export const DeviceIdRouteParamSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.deviceId;
    }
);

export const DeviceNameRouteParamSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.deviceName;
    }
);

export const DeviceFilterTextSelector = createSelector(
    [DeviceNameRouteParamSelector],
    (deviceName) => {
        const deviceNameDisplayText = deviceName || null;

        return i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.FILTER.DEVICE_NAME, {
            deviceNameDisplayText
        });
    }
);

export const ActiveIsSelectedSelector = createSelector(
    [RecoverableContentActivitySelector],
    (uiState) => {
        return pathOr(null, ['filterData', 'activeIsSelected'], uiState);
    }
);

export const CurrentPageSelector = createSelector(
    [RecoverableContentActivitySelector],
    (uiState) => {
        return pathOr(null, ['filterData', 'currentPage'], uiState);
    }
);

export const SelectedPageSizePreference = createSelector(
    [RecoverableContentActivitySelector],
    (uiState) => {
        return pathOr(null, ['filterData', 'pageSizePreference'], uiState);
    }
);

export const CustomerContentActivitySelector = createSelector(
    [
        ProductIdRouteParamSelector,
        DeviceIdRouteParamSelector,
        SelectedCustomerSelector
    ],
    (productId, deviceId, selectedCustomer) => {
        const contentActivity = pathOr(null, ['contentActivity', 'data'], selectedCustomer);

        // The API doesn't filter the active list when a productId or deviceId is present so we are doing it here
        if (contentActivity && contentActivity.active && (productId || deviceId)) {
            const filteredActive = {};

            toPairs(contentActivity.active).forEach(([key, value]) => {
                if ((value.ProductId && value.ProductId.toString() === productId)
                    || (value.PhysicalDeviceId && value.PhysicalDeviceId.toString() === deviceId)) {
                    filteredActive[key] = value;
                }
            });

            return contentActivity.set('active', filteredActive);
        }
        return contentActivity;
    }
);

export const HasActiveContentActivitySelector = createSelector(
    [CustomerContentActivitySelector],
    (contentActivity) => {
        return contentActivity ? !!Object.keys(contentActivity.active).length : false;
    }
);

export const HasLoadedContentActivitySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['contentActivity', 'hasBeenLoaded'], selectedCustomer);
    }
);

export const IsFetchingContentActivitySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['contentActivity', 'isLoadingContentActivity'], selectedCustomer);
    }
);

export const IsLoadingDetails = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['contentActivity', 'isLoadingContentActivityDetails'], selectedCustomer);
    }
);

export const ActiveItemCountSelector = createSelector(
    [CustomerContentActivitySelector],
    (contentActivity) => {
        return (contentActivity && contentActivity.active) ? values(contentActivity.active).length : 0;
    }
);

const EMPTY_CONTENT_ACTIVITY_COLLECTION = [];
export const CustomerContentActivityCollectionSelector = createSelector(
    [
        CustomerContentActivitySelector,
        ActiveIsSelectedSelector,
        MetadataCodeSelector(CODES.ProductLineOfBusinessDeliveryCapability),
        MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapability)
    ],
    (contentActivity, activeIsSelected, productLOBDeliveryCapability, hasProductLOBDeliveryCapabilityLoaded) => {
        if (!contentActivity || !hasProductLOBDeliveryCapabilityLoaded) {
            return EMPTY_CONTENT_ACTIVITY_COLLECTION;
        }

        let selectedActivityList;
        if (activeIsSelected) {
            selectedActivityList = contentActivity.active ? values(contentActivity.active) : null;
        } else {
            selectedActivityList = contentActivity.archived ? values(contentActivity.archived) : null;
        }

        return selectedActivityList.map((item) => {
            const associatedCapability = productLOBDeliveryCapability.items.find((capability) => {
                return item.DeliveryCapability && item.DeliveryCapability.toString() === capability.Value;
            });

            let durationString;
            let difference;

            if (item.Ended) {
                difference = moment(item.Ended).diff(moment(item.Added));
            } else {
                difference = moment().diff(moment(item.Added));
            }

            const duration = difference > 0 ? moment.duration(difference) : moment.duration(moment());
            const H = i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.DURATION.UNITS.HOUR);
            const M = i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.DURATION.UNITS.MINUTE);
            const hours = duration.hours();
            const minutes = duration.minutes();

            if (hours > 0) {
                durationString = `${hours}${H} ${minutes}${M}`;
            } else if (minutes > 0) {
                durationString = `${minutes}${M}`;
            } else {
                durationString = `<1${M}`;
            }
            //A neutral delivery capability code will be 0 which doesn't have
            //a lookup in productLOBDeliveryCapability(20)
            if (associatedCapability) {
                item = item.set('DeliveryCapabilityName', associatedCapability.Name);
            }

            item = item.set('duration', durationString);

            return item ;
        });
    }
);

export const SelectedProductSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['contentActivity', 'selectedProduct'], selectedCustomer);
    }
);

export const SelectedActivitySelector = createSelector(
    [
        SelectedItemIdSelector,
        CustomerContentActivityCollectionSelector,
        SelectedProductSelector
    ],
    (selectedItemId, collection, selectedProduct) => {
        let selectedCollectionItem = null;
        if (!selectedItemId && collection.length) {
            selectedCollectionItem = collection[0];
        } else if (collection.length  > 0) {
            selectedCollectionItem = find(propEq(selectedItemId, 'Id'))(collection);
        }
        if (selectedCollectionItem && selectedProduct && selectedProduct.PricingPlans && selectedProduct.PricingPlans.length) {
            const pricingPlan = selectedProduct.PricingPlans.find((plan) => {
                return plan.Id === selectedCollectionItem.PricingPlanId;
            });
            selectedCollectionItem = Object.assign({}, selectedCollectionItem, {
                pricingPlanName: pricingPlan ? pricingPlan.Name : ''
            });
        }
        return selectedCollectionItem;
    }
);

export const AllContentActivityCollectionSelector = createSelector(
    [CustomerContentActivitySelector],
    (contentActivity) => {
        if (contentActivity) {
            let completeList = [];
            completeList = concat(completeList, values(contentActivity.active));
            completeList = concat(completeList, values(contentActivity.archived));

            return completeList;
        }

        return EMPTY_CONTENT_ACTIVITY_COLLECTION;
    }
);

export const ActiveContentCollectionSelector = createSelector(
    [CustomerContentActivitySelector],
    (contentActivity) => {
        if (contentActivity) {
            return values(contentActivity.active.asMutable({
                deep: true
            }));
        }

        return EMPTY_CONTENT_ACTIVITY_COLLECTION;
    }
);

export const ProductNameSelector = createSelector(
    [
        SelectedProductSelector,
        ProductIdRouteParamSelector
    ],
    (selectedProduct, productId) => {
        return (selectedProduct && selectedProduct.Id.toString() === productId)
            ? selectedProduct.Name : null;
    }
);

export const ProductFilterTextSelector = createSelector(
    [ProductNameSelector],
    (productName) => {
        const productNameDisplayText = productName || i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.PRODUCT.NOT_FOUND);

        return i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.FILTER.PRODUCT_NAME, {
            productNameDisplayText
        });
    }
);

export const IsLoadingProductSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['contentActivity', 'isLoadingProduct'], selectedCustomer);
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['contentActivity', 'recordCount'], selectedCustomer);
    }
);

export const PreviousRouteSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.previousRoute;
    }
);

export const IsPreviousProductsRouteSelector = createSelector(
    [RoutePreviousState],
    (route) => {
        return route.name.includes('index.customercare.customer.customerProducts.detail');
    }
);

export const IsPreviousDevicesRouteSelector = createSelector(
    [RoutePreviousState],
    (route) => {
        return route.name.includes('index.customercare.customer.devices');
    }
);

export const ShowBackBannerSelector = createSelector(
    [RecoverableContentActivitySelector],
    (uiState) => {
        return !!uiState.showBanner;
    }
);

export const ShowRouteBackBannerSelector = createSelector(
    [
        IsPreviousDevicesRouteSelector,
        IsPreviousProductsRouteSelector,
        ShowBackBannerSelector
    ],
    (isPreviousDevicesRoute, isPreviousProductsRoute, showBackBanner) => {
        return showBackBanner && (isPreviousProductsRoute || isPreviousDevicesRoute);
    }
);

export const ContentActivityErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return path(['contentActivity', 'contentActivityError', 'translatedMessage'], selectedCustomer);
    }
);

export const BackBannerTextSelector = createSelector([
    DeviceIdRouteParamSelector,
    DeviceNameRouteParamSelector,
    ProductIdRouteParamSelector,
    ProductNameSelector
],
(deviceId, deviceName, productId, productName) => {
    let bannerText = null;

    if (productId && productName) {
        bannerText = productName ;
    }

    if (deviceId && deviceName) {
        bannerText = deviceName;
    }

    return i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.RETURN_TO, {
        bannerText
    });
}
);

export const IsLoadingContentActivitySelector = createSelector(
    [IsFetchingContentActivitySelector, MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapability)],
    (isFetchingContentActivity, productLOBDeliveryCapabilityLoaded) => {
        return !!(isFetchingContentActivity || !productLOBDeliveryCapabilityLoaded);
    }
);

export const CurrentListHasContentActivitySelector = createSelector(
    [CustomerContentActivityCollectionSelector],
    (customerContentActivity) => {
        return !!(customerContentActivity && customerContentActivity.length > 0);
    }
);

export const HasFilteredContentActivitySelector = createSelector(
    [CustomerContentActivityCollectionSelector, CurrentListHasContentActivitySelector],
    (customerContentActivity, currentListHasContentActivity) => {
        return !!(customerContentActivity && currentListHasContentActivity);
    }
);

export const HasAnyContentActivitySelector = createSelector(
    [AllContentActivityCollectionSelector],
    (allContentActivity) => {
        return !!(allContentActivity && allContentActivity.length > 0);
    }
);

export const ShowListEmptyStateSelector = createSelector(
    [HasAnyContentActivitySelector, CurrentListHasContentActivitySelector],
    (hasAnyContentActivity, currentListHasContentActivity) => {
        return hasAnyContentActivity && !currentListHasContentActivity;
    }
);

export const ShouldShowBackBannerSelector = createSelector(
    [ProductIdRouteParamSelector, DeviceIdRouteParamSelector, ShowRouteBackBannerSelector],
    (productId, deviceId, showBackBanner) => {
        return (productId || deviceId) && showBackBanner;
    }
);

export const EmptyStateGlyphSelector = createSelector(
    [ProductIdRouteParamSelector, DeviceIdRouteParamSelector],
    (productId, deviceId) => {
        return productId || deviceId ? GLYPH.SEARCH : GLYPH.NET_LINK;
    }
);

export const EmptyStateMessageSelector = createSelector(
    [HasAnyContentActivitySelector, ActiveIsSelectedSelector, ProductIdRouteParamSelector, ProductNameSelector, DeviceIdRouteParamSelector, DeviceNameRouteParamSelector],
    (hasAnyContentActivity, activeIsSelected, productId, productName, deviceId, deviceName) => {
        let type;
        const displayName = productId ? productName : deviceName;

        if (hasAnyContentActivity) {
            type = activeIsSelected ?
                i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.FILTER.ACTIVE).toLowerCase() :
                i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.FILTER.ARCHIVED).toLowerCase();
        }

        const emptyStateLocaleString =  (productId && productName) || (deviceId && deviceName) ?
            CustomerLocaleKeys.CONTENT_ACTIVITY.NO_CONTENT_ACTIVITY.FILTERED :
            CustomerLocaleKeys.CONTENT_ACTIVITY.NO_CONTENT_ACTIVITY.GENERAL;

        return i18n.translate(emptyStateLocaleString, {
            type,
            displayName
        });
    }
);
