import Immutable from 'seamless-immutable';
import {pathOr} from 'ramda';
import {
    CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS,
    RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS,
    RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS,
    SET_ORDER_ITEM_TO_DISPLAY_SERVICE_ATTRIBUTES_FOR,
    UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS,
    UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS
} from './actions/customer.convergent.biller.actions';
import {CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT} from './actions/customer.billing.actions';
import {
    APPLY_CREDIT_CONSTANTS,
    CLEAR_APPLY_CREDIT_ERRORS,
    COMPLETE_ORDER_CONSTANTS,
    RETRIEVE_ORDER_SERVICE_DETAILS,
    UPDATE_ORDER_SERVICE_DETAILS
} from './actions/order.actions';
import {ANONYMIZE_CUSTOMER} from './actions/customer.actions';

export const INITIAL_STATE = Immutable({
    data: {},
    isFetchingAccountDetails: false,
    isFetchingAccountUsageDetails: false,
    isFetchingSubscriberSummary: false,
    isFetchingDeposits: false,
    isFetchingInvoices: false,
    isFetchingOrderServiceDetails: false,
    isFetchingSubscriberOfferings: false,
    isFetchingTreatmentDetails: false,
    isUpdatingEntitlementStatus: false,
    isUpdatingOrderServiceDetails: false,
    lastAttemptError: null,
    promiseToPayCreationError: null,
    isCreatingConvergentBillerAdjustment: null,
    orderItemToDisplayServiceAttributesFor: null,
    orderServiceDetailsError: null,
    isApplyingCredit: false,
    applyCreditError: null,
    subscriberOfferings: [],
    updateOrderServiceDetailsError: null
});

export default function convergentBillerReducer(state = INITIAL_STATE, {payload = {},
    type,
    requestObject = {}}) {
    switch (type) {
        case ANONYMIZE_CUSTOMER.SUCCESS:
            return state.set('data', INITIAL_STATE.data);
        case APPLY_CREDIT_CONSTANTS.BEGIN:
            return state
                .set('isApplyingCredit', true)
                .set('applyCreditError', null);
        case APPLY_CREDIT_CONSTANTS.SUCCESS:
        case CLEAR_APPLY_CREDIT_ERRORS:
            return state
                .set('isApplyingCredit', false)
                .set('applyCreditError', null);
        case APPLY_CREDIT_CONSTANTS.FAILURE:
            return state
                .set('isApplyingCredit', false)
                .set('applyCreditError', payloadToError(payload));
        case COMPLETE_ORDER_CONSTANTS.BEGIN:
            return state.set('isFetchingOrderDetails', true);
        case COMPLETE_ORDER_CONSTANTS.FAILURE:
            return state.set('isFetchingOrderDetails', false);
        case COMPLETE_ORDER_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingOrderDetails', false)
                .set('data', populateConvergentBillerOrderDetails(state.data, payload.Order, null, requestObject.customerId, requestObject.orderId));
        case CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.BEGIN:
            return state.set('isCreatingConvergentBillerAdjustment', true);
        case CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.SUCCESS:
        case CREATE_CONVERGENT_BILLER_LEDGER_ADJUSTMENT.FAILURE:
            return state.set('isCreatingConvergentBillerAdjustment', false);
        case CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.BEGIN:
            return state
                .set('promiseToPayCreationError', null)
                .set('isCreatingPromiseToPay', true);
        case CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.SUCCESS:
            return state
                .set('promiseToPayCreationError', null)
                .set('isCreatingPromiseToPay', false);
        case CREATE_CONVERGENT_BILLER_PROMISE_TO_PAY_CONSTANTS.FAILURE:
            return state
                .set('isCreatingPromiseToPay', false)
                .set('promiseToPayCreationError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.BEGIN:
            return state.set('isFetchingAccountDetails', true);
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.SUCCESS:
            return state.
                set('isFetchingAccountDetails', false)
                .set('data', populateConvergentBillerAccountDetails(state.data, payload.Offerings, payload.Standalones, payload.CurrencyCode, requestObject.customerId));
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingAccountDetails', false)
                .set('lastAttemptError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.BEGIN:
            return state
                .set('isFetchingAccountUsageDetails', true)
                .set('data', populateConvergentBillerSelectedServiceId(state.data, requestObject.customerId, requestObject.serviceId));
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('lastAttemptError', null)
                .set('isFetchingAccountUsageDetails', false)
                .set('data', populateConvergentBillerAccountUsageDetails(state.data, payload, requestObject.customerId, requestObject.serviceId));
        case RETRIEVE_CONVERGENT_BILLER_ACCOUNT_USAGE_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingAccountUsageDetails', false)
                .set('lastAttemptError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.BEGIN:
            return state.set('isFetchingInvoices', true);
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingInvoices', false)
                .set('data', populateConvergentBillerInvoices(state.data, payload.Invoices, requestObject.customerId));
        case RETRIEVE_CONVERGENT_BILLER_INVOICES_CONSTANTS.FAILURE:
            return state
                .set('isFetchingInvoices', false)
                .set('lastAttemptError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.BEGIN:
            return state.set('isFetchingSubscriberSummary', true);
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingSubscriberSummary', false)
                .set('data', populateConvergentBillerSubscriberSummary(state.data, payload.SubscriberSummary, requestObject.customerId));
        case RETRIEVE_CONVERGENT_BILLER_SUBSCRIBER_SUMMARY_CONSTANTS.FAILURE:
            return state
                .set('isFetchingSubscriberSummary', false)
                .set('lastAttemptError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.BEGIN:
            return state.set('isFetchingTreatmentDetails', true);
        case RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingTreatmentDetails', false)
                .set('data', populateConvergentBillerTreatmentDetails(state.data, payload.SubscriberTreatmentDetails, requestObject.customerId));
        case RETRIEVE_CONVERGENT_BILLER_TREATMENT_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingTreatmentDetails', false)
                .set('lastAttemptError', payloadToError(payload));
        case UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS.BEGIN:
            return state.set('isUpdatingEntitlementStatus', true);
        case UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS.FAILURE:
        case UPDATE_DASHBOARD_ENTITLEMENT_SHARE_STATUS.SUCCESS:
            return state.set('isUpdatingEntitlementStatus', false);
        case UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.BEGIN:
            return state
                .set('isUpdatingSubscriberTreatment', true)
                .set('updateSubscriberTreatmentError', null);
        case UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.SUCCESS:
            return state
                .set('updateSubscriberTreatmentError', null)
                .set('isUpdatingSubscriberTreatment', false);
        case UPDATE_SUBSCRIBER_TREATMENT_CONSTANTS.FAILURE:
            return state
                .set('isUpdatingSubscriberTreatment', false)
                .set('updateSubscriberTreatmentError', payloadToError(payload));
        case RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.BEGIN:
            return state.set('isFetchingOrderDetails', true);
        case RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingOrderDetails', false)
                .set('data', populateConvergentBillerOrderDetails(state.data, payload.Order, payload.Transaction, requestObject.customerId, requestObject.orderId, payload.Subscribers));
        case RETRIEVE_CONVERGENT_BILLER_ORDER_DETAILS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingOrderDetails', false)
                .set('lastAttemptError', payloadToError(payload));
        case RETRIEVE_ORDER_SERVICE_DETAILS.BEGIN:
            return state
                .set('isFetchingOrderServiceDetails', true)
                .set('orderServiceDetailsError', null);
        case RETRIEVE_ORDER_SERVICE_DETAILS.SUCCESS:
            return state
                .set('isFetchingOrderServiceDetails', false)
                .set('data', populateOrderServiceDetails(state.data, payload.OrderItems, requestObject.CustomerId, payload.OrderId));
        case RETRIEVE_ORDER_SERVICE_DETAILS.FAILURE:
            return state
                .set('isFetchingOrderServiceDetails', false)
                .set('orderServiceDetailsError', payloadToError(payload));
        case RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.BEGIN:
            return state.set('isFetchingDeposits', true);
        case RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.SUCCESS:
            return state
                .set('isFetchingDeposits', false)
                .set('data', populateConvergentBillerDeposits(state.data, payload.Deposits, requestObject.customerId));
        case RETRIEVE_SUBSCRIBER_DEPOSITS_CONSTANTS.FAILURE:
            return state
                .set('isFetchingDeposits', false)
                .set('lastAttemptError', payloadToError(payload));
        case UPDATE_ORDER_SERVICE_DETAILS.BEGIN:
            return state
                .set('isUpdatingOrderServiceDetails', true)
                .set('updateOrderServiceDetailsError', null);
        case UPDATE_ORDER_SERVICE_DETAILS.SUCCESS:
            return state.set('isUpdatingOrderServiceDetails', false);
        case UPDATE_ORDER_SERVICE_DETAILS.FAILURE:
            return state
                .set('isUpdatingOrderServiceDetails', false)
                .set('updateOrderServiceDetailsError', payloadToError(payload));
        case SET_ORDER_ITEM_TO_DISPLAY_SERVICE_ATTRIBUTES_FOR:
            return state.set('orderItemToDisplayServiceAttributesFor', payload);
        default:
            return state;
    }
}

const payloadToError = ({Code, translatedMessage, Severity, FaultData}) => {
    const fault = {
        code: Code,
        message: translatedMessage,
        severity: Severity
    };

    if (pathOr(0, ['length'], FaultData)) {
        Object.assign(fault, {
            faultData: FaultData
        });
    }

    return fault;
};

const mergeIntoStore = (data, toAdd, customerId) => {
    if (data) {
        if (data[customerId]) {
            return data.merge(toAdd, {
                deep: true
            });
        } else {
            return data.merge(toAdd);
        }
    } else {
        return toAdd;
    }
};

export const populateConvergentBillerSubscriberSummary = (data, subscriberSummary, customerId) => {
    const toAdd = {
        [customerId]: {
            subscriberSummary
        }
    };
    const currentSubscriberSummary = data && (customerId in data && 'subscriberSummary' in data[customerId]);
    const currentKeys = currentSubscriberSummary && data && Object.keys(data[customerId].subscriberSummary).toString();
    const newKeys = currentKeys && subscriberSummary && Object.keys(subscriberSummary).toString();

    if (currentKeys && currentKeys !== newKeys) {
        return data.setIn([customerId, 'subscriberSummary'], subscriberSummary);
    }
    return mergeIntoStore(data, toAdd, customerId);
};

export const populateConvergentBillerInvoices = (data, invoices, customerId) => {
    const toAdd = {
        [customerId]: {
            invoices
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

export const populateConvergentBillerDeposits = (data, deposits, customerId) => {
    const toAdd = {
        [customerId]: {
            deposits
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

const populateConvergentBillerTreatmentDetails = (data, treatmentDetails, customerId) => {
    const toAdd = {
        [customerId]: {
            treatmentDetails
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

export const populateConvergentBillerAccountDetails = (data, offerings, standalones, currencyCode, customerId) => {
    const toAdd = {
        [customerId]: {
            accountDetails: {
                currencyCode: currencyCode,
                offerings: offerings,
                standalones
            }
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

export const populateConvergentBillerAccountUsageDetails = (data, payload, customerId, serviceId) => {
    const toAdd = {
        [customerId]: {
            usageDetails: {
                [serviceId]: payload
            }
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

export const populateConvergentBillerSelectedServiceId = (data, customerId, serviceId) => {
    const toAdd = {
        [customerId]: {
            selectedServiceId: serviceId
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

const populateOrderServiceDetails = (data, orderServiceDetails, customerId, orderId) => {
    const toAdd = {
        [customerId]: {
            orderServiceDetails: {
                [orderId]: orderServiceDetails
            }
        }
    };
    return mergeIntoStore(data, toAdd, customerId);
};

const populateConvergentBillerOrderDetails = (data, orderDetails, orderTransaction, customerId, orderId, orderSubscribers) => {
    let toAdd = Immutable({})
        .setIn([customerId, 'orderDetails', orderId], orderDetails)
        .setIn([customerId, 'orderDetails', orderId, 'OrderStatus'], (orderDetails || {}).OrderStatus || 0)
        .setIn([customerId, 'orderDetails', orderId, 'CanBeCanceled'], !!(orderDetails || {}).CanBeCanceled);
    if (orderTransaction) {
        toAdd = toAdd.setIn([customerId, 'orderTransactions', orderId], orderTransaction);
    }
    if (orderSubscribers) {
        toAdd = toAdd.setIn([customerId, 'orderSubscribers', orderId], orderSubscribers);
    }
    return mergeIntoStore(data, toAdd, customerId);
};
